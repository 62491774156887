import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      login: true,
    }
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import(/* webpackChunkName: "live" */ '../views/Live.vue'),
    meta: {
      requiresAuth: true,
      background: 'live',
    }
  },
  {
    path: '/registrations',
    name: 'Registrations',
    component: () => import(/* webpackChunkName: "registrations" */ '../views/Registrations.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/callminutes',
    name: 'Callminutes',
    component: () => import(/* webpackChunkName: "callminutes" */ '../views/Callminutes.vue'),
    meta: {
      requiresAuth: true,
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
