import { createStore } from "vuex" 
import account from './modules/account'
import createPersistedState from "vuex-persistedstate";

const store = createStore({
   state:{
      name: "Vue"
   },
   modules: {
    account,
   },
   plugins: [createPersistedState()]
})

export default store