<template>
    <header class="dubline-bg">
        <div id="head" class="flex items-center p-5">
            <div class="logo text-white">
                <dubline-logo/>
            </div>

            <div class="flex hidden md:block justify-around text-white text-xl md:text-lg ml-auto">
                <router-link class="relative mr-4" to="/live">LIVE</router-link>
                <router-link class="relative" to="/registrations">AANMELDINGEN</router-link>
            </div>

            <div @click="menuDropdown = !menuDropdown" class="open-dropdown-menu md:hidden text-white ml-auto">
                <div class="menu-icon">
                    <menu-icon/>
                </div>
            </div>
        </div>
        <div v-show="menuDropdown" class="menu-backdrop absolute w-full h-screen top-0 left-0 z-40 px-5">
            <div class="dropdown-menu">
                <div class="close-dropdown-menu text-right h-12 mt-3" @click="menuDropdown = !menuDropdown">
                    <div class="close-icon inline-block rounded-lg rounded-b-none bg-white p-3 pt-4 pb-5">
                       <close-icon/>
                    </div>
                </div>

                <div class=" bg-white rounded-lg rounded-tr-none p-3">
                   <p class="text-sm text-gray-500">Heb je een nieuw business idee voor Dubline, omschrijf dit dan onderstaand kort en bondig en het wordt opgenomen in de to-do list.</p>
                    <textarea class="w-full px-3 py-2 my-2 text-gray-700 border rounded-lg focus:outline-none" rows="4" v-model="mailContent"></textarea>
                    <button @click="sendMail(mailContent)" type="button" class="btn-selected-invert w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm">
                        Verzenden
                    </button>
                </div>
            </div>
        </div>
        <div @click="closeAlert()" v-if="alertOpen" class="text-white px-6 py-4 border-0 absolute w-full mb-4 z-10 top-0 dubline-yellow">
            <span class="text-xl inline-block mr-5 align-middle">
            <i class="fas fa-bell"></i>
            </span>
            <span class="inline-block align-middle mr-8">
            <b class="capitalize">Mail is verzonden!</b>
            </span>
            <button class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none">
            <span><close-icon/></span>
            </button>
        </div>
    </header>
</template>

<script>

export default {
    name: 'TheHeader',

    data() {
        return {
            menuDropdown: false,
            mailContent: '',
            alertOpen: false
        }
    }, 

    methods: {
        showMenu()
        {
            this.menuDropdown = true;
        },

        sendMail(mailContent)
        {
            let bodyFormData = new FormData();
            bodyFormData.append('mailContent', mailContent);
            bodyFormData.append('sender', this.$store.state.account.userName);
            this.$api.post('https://provisioning.dubline.nl/test/rocketeer/send_dashboard_mail', bodyFormData)
            .then(response => {
                console.log(response);
                this.mailContent = '';
                this.menuDropdown = false;
                this.alertOpen = true;
            })
            .catch(error => {
                console.log(error)
            })
        
        },
        closeAlert: function(){
        this.alertOpen = false;
        }
    },
}
</script>