const state = {
    loggedIn: false,
    userId: null,
    userName: null,
  };
  
  const mutations = {
    logout(state) {
      state.loggedIn = false,
      state.userId = null;
      state.userName = null;
    },
    login(state, user) {
      state.loggedIn = true,
      state.userId = user.id;
      state.userName = user.name;
    }
  };
  
  export default {
    state,
    mutations
  }