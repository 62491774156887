<template class="full-screen">
    <img
      src="@/assets/wave.png"
      class="fixed hidden lg:block inset-0 h-full"
      style="z-index: -1;"
    />
    <div
      class="w-screen h-screen flex flex-col justify-center items-center lg:grid lg:grid-cols-2"
    >
      <img
        src="@/assets/unlock.svg"
        class="hidden lg:block w-1/2 hover:scale-150 transition-all duration-500 transform mx-auto"
      />
      <form class="flex flex-col justify-center items-center w-1/2">
        <img src="@/assets/avatar.svg" class="w-32" />
        <h2
          class="my-8 font-display font-bold text-3xl text-gray-700 text-center"
        >
          Dubline Dashboard
        </h2>
        <div class="relative">
          <i class="fa fa-user absolute text-primarycolor text-xl"></i>
          <input
            type="text"
            placeholder="username"
            class="pl-8 border-b-2 font-display focus:outline-none focus:border-primarycolor transition-all duration-500 text-lg"
            v-model="email"
          />
        </div>
        <div class="relative mt-8">
          <i class="fa fa-lock absolute text-primarycolor text-xl"></i>
          <input
            type="password"
            placeholder="password"
            class="pl-8 border-b-2 font-display focus:outline-none focus:border-primarycolor transition-all duration-500 text-lg"
            v-model="password"
          />
        </div>
        <!-- <a href="#" class="self-end mt-4 text-gray-600 font-bold"
          >Forgot password?</a
        > -->
        <a
          @click="login"
          href="#"
          class="py-3 px-20 bg-primarycolor rounded-full text-white font-bold uppercase text-lg mt-4 transform hover:translate-y-1 transition-all duration-500"
          >Login</a
        >
      </form>
    </div>

</template>

<script>

export default {
    data() {
        return {
            email: '',
            password: '',
        }
    },

    methods: {
        login()
        {
            let bodyFormData = new FormData();
            bodyFormData.append('email', this.email);
            bodyFormData.append('password', this.password);
            console.log('try logging in');
            this.$api.post('https://dashboard.dubline.nl/api/public/api/login', bodyFormData)
            .then(response => {
                if(response.data.status_code != 200)
                {
                    return;
                }
                this.$store.commit('login', response.data.account);
                this.$router.push('/live');
            })
            .catch(error => {
                console.log(error)
            })
        },
        logout()
        {
            this.$store.commit('logout');
            console.log(this.$store);
        },

        checkUser()
        {   
            console.log('check state for account');
            if(!this.$store.state.account.loggedIn)
            {
                console.log('no state for account');
                return;
            }
            console.log('checking api for account');
            this.$api.get('https://dashboard.dubline.nl/api/public/api/check-user/' + this.$store.state.account.userId)
            .then(response => {
                if(response.data.status_code != 200)
                {
                    this.logout();
                }
                console.log(response);
                this.$router.push('/live');
            })
            .catch(error => {
                console.log(error);
            })
        }
    },

    mounted() 
    {
        this.checkUser();
    }
    
}
</script>