import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store';
import './index.css';
import 'es6-promise/auto'

import TheHeader from '@/components/TheHeader';
import TheNav from '@/components/TheNav';
import Alert from '@/components/Alert'

import DublineLogo from '@/components/svgs/DublineLogo';
import MenuIcon from '@/components/svgs/MenuIcon';
import CloseIcon from '@/components/svgs/CloseIcon';
import './registerServiceWorker';

const app = createApp(App);

// components
app.component('the-header', TheHeader);
app.component('the-nav', TheNav);
app.component('alert-blue', Alert);

//svgs
app.component('dubline-logo', DublineLogo);
app.component('menu-icon', MenuIcon);
app.component('close-icon', CloseIcon);

app.config.globalProperties.$api = axios.create({ headers: { 'Content-Type': 'multipart/form-data' }});
app.use(store);
console.log(store);
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.state.account.loggedIn) {
        next({
          path: '/',
        })
      } else {
        next()
      }
    } else {
      next() // make sure to always call next()!
    }
  })
app.use(router, axios);
app.mount('#app');
