<template>
    <svg width="20" height="6" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.559 5.71c.052-.012.1-.025.146-.038l.147-.04a2.461 2.461 0 001.25-.712c.338-.358.546-.791.625-1.299l.029-.058a.136.136 0 00.01-.06v-.526a.771.771 0 01-.03-.118.771.771 0 00-.029-.117 2.347 2.347 0 00-.713-1.28 2.437 2.437 0 00-1.318-.634l-.059-.03A.136.136 0 0017.56.79h-.528c-.078.013-.156.03-.234.049-.078.02-.15.042-.215.068a2.378 2.378 0 00-1.338.957c-.319.469-.452 1.01-.4 1.621.026.547.237 1.029.635 1.446.397.416.875.664 1.435.742a.124.124 0 00.059.03c.026.006.045.009.058.009h.528zm-14.473 0c.065-.012.137-.028.215-.048.078-.02.15-.036.215-.049a2.363 2.363 0 001.338-.967 2.517 2.517 0 00.42-1.61c-.027-.548-.238-1.033-.635-1.456A2.368 2.368 0 003.203.828a.124.124 0 00-.058-.03.267.267 0 00-.06-.009H2.56c-.052.013-.101.026-.147.04a.464.464 0 01-.127.019 2.437 2.437 0 00-1.894 2.03c0 .014-.007.03-.02.05a.095.095 0 00-.02.049v.546c.014.066.03.13.05.196.019.065.035.13.048.195.156.495.4.889.733 1.182.332.293.752.485 1.26.576l.058.03c.013.006.033.009.059.009h.527zm7.246 0c.052-.012.11-.025.176-.038.065-.013.124-.026.176-.04a2.316 2.316 0 001.367-.927c.338-.475.495-1.025.469-1.65-.027-.547-.238-1.032-.635-1.455a2.465 2.465 0 00-1.436-.772.536.536 0 00-.068-.03.16.16 0 00-.049-.009h-.547c-.052.013-.11.026-.176.04a3.816 3.816 0 00-.175.038 2.316 2.316 0 00-1.368.928c-.338.475-.494 1.025-.468 1.65.026.547.237 1.035.634 1.465.398.43.876.684 1.436.762a.12.12 0 01.068.02c.02.012.036.019.05.019h.546z" fill="#FFF" fill-rule="nonzero"/>
    </svg>
</template>

<script>
export default {
    
}
</script>