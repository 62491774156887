<template>
    <div v-if="!$route.meta.login">
      <the-header></the-header>
      <div id="page" class="full-height" :class="$route.meta.background == 'live' ? 'live-bg overflow-hidden' : 'dubline-linearbg'">
        <the-nav></the-nav>
        <router-view></router-view>
      </div>
    </div>
    <div v-else>
      <div id="page" class="h-screen">
        <router-view></router-view>
      </div>
    </div>

    
</template>