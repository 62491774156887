<template>
    <div id="nav" class="flex md:hidden justify-around text-white text-xl p-3">
        <router-link class="relative" to="/live">LIVE</router-link>
        <router-link class="relative" to="/registrations">AANMELDINGEN</router-link>
        <!-- <router-link class="relative" to="/callminutes">BELMINUTEN</router-link> -->
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>